import { useEffect, useRef } from "react";

import { RealtimeClient } from "@/lib/sdk";

/**
 * Custom Hook per rilevare cambiamenti di navigazione e visibilità su dispositivi mobili.
 *
 * @param {Function} functionA - Funzione da eseguire su navigazioni specifiche (es. reload, ritorno alla pagina).
 * @param {boolean} isMobile - Flag che indica se il dispositivo è mobile.
 * @param setOpenSessionExpired
 * @param client
 * @param controlPanelConnected
 */
const useNavigationEvents = (
  functionA: () => void,
  isMobile: boolean,
  setOpenSessionExpired: (value: boolean) => void,
  client: RealtimeClient,
  controlPanelConnected: boolean,
) => {
  // Riferimenti per mantenere lo stato tra i re-render
  const isNavigatingAwayRef = useRef(false);
  const isNotFirstTime = localStorage.getItem("firstTime") === "false";

  useEffect(() => {
    // Se non è un dispositivo mobile, non fare nulla
    if (!isMobile) {
      return;
    }

    /**
     * Funzione da eseguire quando si naviga via dalla pagina.
     */
    const handleBeforeUnload = () => {
      isNavigatingAwayRef.current = true;
      sessionStorage.setItem("isNavigatingAway", "true");
      functionA();
    };

    /**
     * Funzione da eseguire quando la pagina viene mostrata (dopo una navigazione o un reload).
     */
    const handlePageShow = () => {
      if (!client.isConnected() && controlPanelConnected && isNotFirstTime) {
        setOpenSessionExpired(true);
        return;
      }
    };

    /**
     * Funzione da eseguire quando lo stato di visibilità cambia.
     */
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!client.isConnected() && controlPanelConnected && isNotFirstTime) {
          setOpenSessionExpired(true);
        }
      }
    };

    /**
     * Funzione da eseguire quando la finestra guadagna focus.
     */
    const handleFocus = () => {
      if (!client.isConnected() && controlPanelConnected && isNotFirstTime) {
        setOpenSessionExpired(true);
      }
    };

    // Aggiunge gli event listeners
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pageshow", handlePageShow);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    // Rimuove gli event listeners al momento dello smontaggio del componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pageshow", handlePageShow);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, [functionA, isMobile]); // Aggiungi isMobile alle dipendenze
};

export default useNavigationEvents;
