import { Microphone01Icon } from "@translated/babele-react";
import clsx from "clsx";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { MicrophoneOffIcon, StopIcon } from "@/assets/images/icons.tsx";
import { ControlPanel } from "@/context/ControlPanel.tsx";
import { QualityAudioBanner } from "@/features/chat/components/QualityAudioBanner.tsx";
import { ReachedLimitsBanner } from "@/features/chat/components/ReachedLimitsBanner.tsx";
import { SessionExpiredBanner } from "@/features/chat/components/SessionExpiredBanner.tsx";
import Conversation from "@/features/conversation/Conversation.tsx";
import { ItemType } from "@/lib/sdk";
import { CallLara } from "@/routes/call-lara/CallLara.tsx";
import { useDataStore } from "@/store/store.ts";
import Pulse from "@/ui/PulseMic/Pulse.tsx";
import Spinner from "@/ui/Spinner/Spinner.tsx";
import { Badge } from "@/ui/badge/Badge.tsx";

export type ChatProps = {
  items: ItemType[];
  isRecording: boolean;
  handleStartRecording: () => void;
  handleStopRecording: () => void;
  isLaraSpeaking: boolean;
  reachedLimit: boolean;
  connectConversation: () => void;
  disconnectConversation: () => void;
  openSessionExpired: boolean;
  setOpenSessionExpired: (value: boolean) => void;
  openDialogReachedLimit: boolean;
  setOpenDialogReachedLimit: (value: boolean) => void;
  showTooltipHoldToPress: boolean;
};

export const ChatMobile = ({
  items,
  isRecording,
  handleStartRecording,
  handleStopRecording,
  isLaraSpeaking,
  reachedLimit,
  connectConversation,
  disconnectConversation,
  openSessionExpired,
  setOpenSessionExpired,
  openDialogReachedLimit,
  setOpenDialogReachedLimit,
  showTooltipHoldToPress,
}: ChatProps) => {
  const { t } = useTranslation();
  const controlPanel = useContext(ControlPanel);
  const [audioQualityBanner, setAudioQualityBanner] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const { recording_error } = useDataStore();

  const reconnectLara = () => {
    disconnectConversation();
    localStorage.setItem("reconnect", "true");
    window.location.reload();
  };

  return (
    <>
      {!controlPanel.connected ? (
        <CallLara connectConversation={connectConversation} />
      ) : (
        <div
          className={clsx(
            "grid grid-rows-[minmax(0,1fr),auto]",
            "h-full",
            "bg-neutral-25",
            "rounded-xs",
            "overflow-hidden",
          )}
        >
          {items?.length < 2 && !reachedLimit ? (
            <Spinner />
          ) : (
            <Conversation items={items} />
          )}

          <div className="flex flex-col items-center justify-center">
            {audioQualityBanner && (
              <QualityAudioBanner
                setAudioQualityBanner={setAudioQualityBanner}
              />
            )}

            <SessionExpiredBanner
              reconnectLara={reconnectLara}
              open={openSessionExpired}
              setOpen={setOpenSessionExpired}
            />

            <ReachedLimitsBanner
              reconnectLara={reconnectLara}
              open={openDialogReachedLimit}
              setOpen={setOpenDialogReachedLimit}
            />

            {reachedLimit && (
              <Badge
                className={"w-fit bg-neutral-25"}
                variant={{ tone: "red", mode: "outline" }}
              >
                {t("badge:reached_limit")}
              </Badge>
            )}

            <span className="py-4 flex items-center justify-center relative">
              <Pulse
                enabled={isRecording}
                isLaraSpeaking={isLaraSpeaking}
                triggerAnimation={triggerAnimation}
                reachedLimit={reachedLimit}
                setOpenDialogReachedLimit={setOpenDialogReachedLimit}
                handleStartRecording={handleStartRecording}
                handleStopRecording={handleStopRecording}
                setTriggerAnimation={setTriggerAnimation}
                disabled={items?.length < 2 && !reachedLimit}
                showTooltipHoldToPress={showTooltipHoldToPress}
              >
                {recording_error ? (
                  <MicrophoneOffIcon />
                ) : isRecording ? (
                  <StopIcon />
                ) : (
                  <Microphone01Icon />
                )}
              </Pulse>
            </span>
          </div>
        </div>
      )}
    </>
  );
};
