import * as RTooltip from "@radix-ui/react-tooltip";
import { token } from "@translated/babele-react";
import clsx from "clsx";
import { forwardRef, ReactNode } from "react";

export type TooltipProps = RTooltip.TooltipProps & {
  trigger: ReactNode;
  title: string;
  description?: string;
  align?: RTooltip.TooltipContentProps["align"];
  side?: RTooltip.TooltipContentProps["side"];
  hasArrow?: boolean;
  textColor?: string;
  bgColor?: string;
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  function Tooltip(
    {
      trigger,
      align = "center",
      side = "top",
      hasArrow = true,
      title,
      description,
      textColor = "text-primary-1000",
      bgColor = "primary-400",
      ...props
    },
    ref,
  ) {
    const fill = token(bgColor as unknown as "primary-400" | "critical-100");
    return (
      <RTooltip.Provider>
        <RTooltip.Root delayDuration={300} {...props}>
          <RTooltip.Trigger asChild data-testid="tooltip-trigger">
            {trigger}
          </RTooltip.Trigger>
          <RTooltip.Portal>
            <RTooltip.Content
              className={clsx(
                "z-20",
                "select-none",
                "rounded-sm",
                "p-4",
                `bg-${bgColor}`,
                "shadow-xl",
                "transition-all",
                props.open ? "animate-fade-in" : "animate-fade-out",
              )}
              align={align}
              side={side}
              sideOffset={hasArrow ? 6 : 7}
              ref={ref}
              data-testid="tooltip-content"
            >
              <div className="flex flex-col gap-1 max-w-xs">
                <div
                  className={clsx(
                    textColor,
                    "font-medium",
                    "text-sm break-words",
                    "text-center",
                  )}
                >
                  {title}
                </div>
                {description && (
                  <p className={clsx("text-neutral-600", "text-md")}>
                    {description}
                  </p>
                )}
              </div>
              {hasArrow && (
                <RTooltip.Arrow
                  fill={fill}
                  className={clsx("h-2 w-4")}
                  data-testid="tooltip-arrow"
                />
              )}
            </RTooltip.Content>
          </RTooltip.Portal>
        </RTooltip.Root>
      </RTooltip.Provider>
    );
  },
);
