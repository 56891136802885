import * as Ariakit from "@ariakit/react";
import * as RTooltip from "@radix-ui/react-tooltip";
import { init as sentryInit } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Spinner } from "@translated/babele-react";
import { HTTPError } from "ky";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router";

import "./translations/i18n.ts";
import { createBrowserRouter } from "react-router-dom";

import { makeRoutes } from "@/routes";

import { envVars } from "../envVars.ts";

import "@/main.css";
import ControlPanelContext from "@/context/ControlPanel.tsx";

if (envVars.VITE_SENTRY_DNS) {
  sentryInit({
    dsn: envVars.VITE_SENTRY_DNS,
  });
}

const retryFn = (count: number, err: unknown) => {
  if (err instanceof HTTPError) {
    if ([401, 403].includes(err.response.status)) {
      return false;
    }
  }

  return count < 3;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryFn,
      staleTime: 1000 * 10,
    },
    mutations: {
      retry: retryFn,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <RTooltip.Provider>
      <ControlPanelContext>
        <RouterProvider
          fallbackElement={
            <div className="grid h-full place-items-center">
              <Ariakit.VisuallyHidden>
                Loading initial data...
              </Ariakit.VisuallyHidden>
              <Spinner />
            </div>
          }
          router={createBrowserRouter(makeRoutes())}
        />
        <Toaster position={"top-center"} />
      </ControlPanelContext>
    </RTooltip.Provider>
    {envVars.VITE_IS_REACTQUERYDEVTOOLS_ENABLED && <ReactQueryDevtools />}
  </QueryClientProvider>,
);
