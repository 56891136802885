import clsx from "clsx";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { ControlPanel } from "@/context/ControlPanel.tsx";
import { Chat } from "@/features/chat/Chat.tsx";
import { ChatMobile } from "@/features/chat/ChatMobile.tsx";
import { ConversationHeader } from "@/features/conversation-header/ConversationHeader.tsx";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { RealtimeEvent } from "@/routes/console-page/types.ts";
import { getInstructions } from "@/utils/conversation_config";

import { envVars } from "../../../envVars.js";
import { WavRecorder, WavStreamPlayer } from "../../lib/wavtools/index.js";

import "./style.css";
import { Header } from "@/features/header";
import useNavigationEvents from "@/hooks/useVisibilityChange.ts";
import { RealtimeClient, ItemType } from "@/lib/sdk";
import { changeAppLanguage, localizedPath } from "@/translations/i18n.ts";
import { showErrorToast } from "@/ui/CustomErrorToast/showErrorToast.tsx";
import { useDataStore } from "@/store/store.ts";

const LOCAL_RELAY_SERVER_URL = envVars.VITE_LOCAL_RELAY_SERVER_URL;

const loadChatScriptByURL = () => {
  const script = document.createElement("script");
  script.id = "hs-script-loader";
  script.src = `//js.hs-scripts.com/${envVars.VITE_HUBSPOT_PORTAL_ID}.js`;
  document.body.appendChild(script);
};

const loadClarityScript = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${envVars.VITE_CLARITY_KEY}");
  `;
  document.body.appendChild(script);
};

export const ChatPage = () => {
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  const location = useLocation();

  const {
    setData: setControlPanel,
    showClientBars,
    connected,
  } = useContext(ControlPanel);

  useEffect(() => {
    const decodedPathname = decodeURIComponent(location.pathname);
    const localized = localizedPath[languageCode];
    const codeLngFromLocation = Object.entries(localizedPath).find(
      ([, value]) => decodeURIComponent(value) === decodedPathname,
    );

    if (codeLngFromLocation && location.pathname !== localized) {
      const newPath = codeLngFromLocation[0] as keyof typeof localizedPath;

      if (newPath in localizedPath) {
        void changeAppLanguage(newPath ?? "en");
      }
    }
  }, [i18n, languageCode, location.pathname]);

  useEffect(() => {
    if (envVars.VITE_IS_HUBSPOT_ENABLED) {
      loadChatScriptByURL();
    }
    if (envVars.VITE_CLARITY_ENABLED) {
      loadClarityScript();
    }
  }, []);

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 }),
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 }),
  );

  const hostname = window.location.hostname;
  const queryParams = new URLSearchParams(location.search);
  const user = hostname === "ciid.laralive.com" ? "CIID" : queryParams.get("u");
  const socketUrl = user
    ? LOCAL_RELAY_SERVER_URL + `?u=${user}`
    : LOCAL_RELAY_SERVER_URL;

  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({ url: socketUrl }),
  );

  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());
  const { setDataStore, recording_error } = useDataStore();

  const recorder = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => {
      console.table(err);
      setDataStore({ recording_error: true });
    },
  );

  const [items, setItems] = useState<ItemType[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [startRecordTime, setStartRecordTime] = useState(0);
  const [isLaraSpeaking, setIsLaraSpeaking] = useState(false);
  const [reachedLimit, setReachedLimit] = useState(false);

  const FIRST_USER_MESSAGE = `Hello!`;

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    // Update Context
    setControlPanel({ connected: true, disconnect: disconnectConversation });
    setItems(client.conversation.getItems());

    // Connect to microphone
    // TODO handle when user does not has the mic
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();

    // Ping event every 10 seconds
    setInterval(() => {
      if (client.isConnected()) {
        client.sendPing();
      }
    }, 10 * 1000);

    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: FIRST_USER_MESSAGE,
        // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
      },
    ]);
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", disconnectConversation);
    return () => {
      window.removeEventListener("beforeunload", disconnectConversation);
    };
  }, []);

  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;

    try {
      await wavRecorder.end();
    } catch (e) {
      console.error("Error during the conversation disconnect", e);
    }

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();

    setControlPanel({ connected: false });
  }, [setControlPanel]);
  const [showTooltipHoldToPress, setShowTooltipHoldToPress] = useState(false);

  const startRecording = async () => {
    const client = clientRef.current;
    if (!client.isConnected()) {
      setOpenSessionExpired(true);
      return;
    }

    if (reachedLimit || recording_error) {
      return;
    }

    recorder.startRecording();
    setIsRecording(true);
    setShowTooltipHoldToPress(false);
    setStartRecordTime(Date.now());
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      client.cancelResponse();
    }

    try {
      await wavRecorder.record((data: { mono: Float32Array }) =>
        client.appendInputAudio(data.mono),
      );
    } catch (e) {
      console.error("Error during recording", e);
    }
  };

  const stopRecording = async () => {
    const duration = (Date.now() - startRecordTime) / 1000;
    setIsRecording(false);
    recorder.stopRecording();
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    if (recording_error) {
      return;
    }

    if (wavRecorder.isRecording()) {
      await wavRecorder.pause();
      if (duration >= 0.5) {
        client.createResponse();
      } else {
        client.clearBuffer();
        setShowTooltipHoldToPress(true);
      }
    }
  };

  // Auto-scroll
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll("[data-conversation-content]"),
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  // Set up render loops for the visualization canvas
  const isLaraSpeakingRef = useRef(isLaraSpeaking);
  useEffect(() => {
    isLaraSpeakingRef.current = isLaraSpeaking;
  }, [isLaraSpeaking]);

  useEffect(() => {
    let isLoaded = true;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    let silenceCount = 0;
    const silenceThreshold = 10;
    const speakingThreshold = 0.1;

    const render = () => {
      if (!isLoaded) {
        return;
      }

      if (serverCanvas) {
        if (!serverCanvas.width || !serverCanvas.height) {
          serverCanvas.width = serverCanvas.offsetWidth;
          serverCanvas.height = serverCanvas.offsetHeight;
        }
        serverCtx = serverCtx || serverCanvas.getContext("2d");
        if (serverCtx) {
          serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
          const result = wavStreamPlayer.analyser
            ? wavStreamPlayer.getFrequencies("voice")
            : { values: new Float32Array([0]) };

          const peakVolume = Math.max(...result.values);
          if (peakVolume > speakingThreshold) {
            silenceCount = 0;
            if (!isLaraSpeaking) {
              setIsLaraSpeaking(true);
            }
          } else {
            silenceCount += 1;
            if (silenceCount > silenceThreshold) {
              if (isLaraSpeaking) {
                setIsLaraSpeaking(false);
              }
            }
          }
        }
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, [isConnected, showClientBars, isLaraSpeaking]);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    const instructions = getInstructions();
    client.updateSession({
      instructions: instructions,
      input_audio_transcription: { model: "whisper-1" },
      temperature: 0.6,
      model: "gpt-4o-realtime-preview-2024-12-17",
    });

    // handle realtime events from client + server for event logging
    client.on("realtime.event", async (realtimeEvent: RealtimeEvent) => {
      if (realtimeEvent.event.type === "quota.limit_hit") {
        await handleStopRecording();
        setReachedLimit(true);
        if (!openDialogReachedLimit) {
          setOpenDialogReachedLimit(true);
        }
      }
    });
    client.on("error", (event: string) => showErrorToast(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id, isMobile);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000,
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, []);

  const handleStartRecording = () => {
    if (items?.length >= 2 && isConnected) {
      return startRecording();
    }
  };

  const handleStopRecording = () => {
    if (items?.length >= 2 && isConnected) {
      return stopRecording();
    }
  };

  const buttonRef = useRef<HTMLSpanElement | null>(null);
  const [openSessionExpired, setOpenSessionExpired] = useState(false);
  const [openDialogReachedLimit, setOpenDialogReachedLimit] = useState(false);

  const propsToChat = {
    items,
    isRecording,
    handleStartRecording,
    handleStopRecording,
    buttonRef,
    isLaraSpeaking,
    reachedLimit,
    connectConversation,
    disconnectConversation,
    isConnected,
    openSessionExpired,
    setOpenSessionExpired,
    openDialogReachedLimit,
    setOpenDialogReachedLimit,
    showTooltipHoldToPress,
  };

  const navigate = useNavigate();
  const handleErrorAndNavigate = useCallback(() => {
    disconnectConversation();
    setControlPanel({ connected: false });
    navigate(0);
  }, [disconnectConversation, navigate, setControlPanel]);

  const client = clientRef.current;

  useNavigationEvents(
    handleErrorAndNavigate,
    isMobile,
    setOpenSessionExpired,
    client,
    connected,
  );

  useEffect(() => {
    if (reachedLimit) {
      handleStopRecording();
    }
  }, [handleStopRecording, reachedLimit]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      () => {
        setDataStore({ recording_error: false });
      },
      () => {
        setDataStore({ recording_error: true });
      },
    );
  }, [setDataStore]);

  useEffect(() => {
    if (showTooltipHoldToPress) {
      const timer = setTimeout(() => {
        setShowTooltipHoldToPress(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltipHoldToPress]);

  /* Render */
  return (
    <>
      <div
        className={clsx(
          "bg-laraBase bg-cover",
          "h-full w-full",
          "grid",
          "grid-cols-1",
          "grid-rows-[auto,1fr]",
        )}
      >
        <Header />
        <main
          className={clsx(
            "z-0 overflow-hidden z-10",
            isMobile ? "px-0" : "px-2 pb-2",
          )}
        >
          <div
            className={clsx(
              "bg-neutral-25",
              "lg:rounded-lg rounded-t-lg transition-colors",
              "h-full",
              "grid grid-rows-[auto,1fr,auto]",
            )}
          >
            <ConversationHeader />
            <canvas
              ref={serverCanvasRef}
              style={{
                display: "none",
              }}
            />

            {isMobile ? (
              <ChatMobile {...propsToChat} />
            ) : (
              <Chat {...propsToChat} />
            )}

            {!isMobile && (
              <div className={"py-4 text-center text-sm"}>
                <Trans
                  i18nKey="commons:terms"
                  components={{
                    1: (
                      <a
                        className="text-primary-1000"
                        href={`${envVars.VITE_LARA_ENDPOINT}/${t(
                          "main_menu:links:terms_and_conditions",
                        )}`}
                      />
                    ),
                    2: (
                      <a
                        className="text-primary-1000"
                        href={`${envVars.VITE_LARA_ENDPOINT}/${t(
                          "main_menu:links:privacy_policy",
                        )}`}
                      />
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
};
