import { Button, SpinnerIcon, VolumeMaxIcon } from "@translated/babele-react";
import { clsx } from "clsx";
import { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PauseIcon } from "@/assets/images/icons.tsx";
import { ControlPanel } from "@/context/ControlPanel.tsx";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { showErrorToast } from "@/ui/CustomErrorToast/showErrorToast.tsx";

const CustomAudioPlayer = ({
  audioSrc,
  audioId,
  isUserAudio,
}: {
  audioSrc: string;
  audioId: string;
  isUserAudio: boolean;
}) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const gainNodeRef = useRef<GainNode | null>(null);
  const { currentPlayingAudioId, setCurrentPlayingAudioId } =
    useContext(ControlPanel);
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContext.createMediaElementSource(audio);
      const gainNode = audioContext.createGain();

      // Set gain value
      gainNode.gain.value = isUserAudio ? 1.0 : isMobile ? 3.0 : 1.5;

      // Connect nodes
      source.connect(gainNode).connect(audioContext.destination);

      // Save references
      audioContextRef.current = audioContext;
      gainNodeRef.current = gainNode;

      // Warm up the AudioContext
      const warmup = () => {
        if (audioContext.state === "suspended") {
          audioContext.resume();
        }
      };

      warmup();

      return () => {
        if (audioContextRef.current) {
          audioContextRef.current.close();
        }
      };
    }
  }, [isMobile, isUserAudio]);

  useEffect(() => {
    if (currentPlayingAudioId !== audioId && isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    }
  }, [currentPlayingAudioId, audioId, isPlaying]);

  const togglePlayPause = async () => {
    const audio = audioRef.current;

    if (audio) {
      if (isPlaying && currentPlayingAudioId === audioId) {
        // Se è lo stesso audio, metti in pausa
        audio.pause();
        setIsPlaying(false);
        setCurrentPlayingAudioId(null);
      } else {
        // Se è un audio diverso o nessun audio è in riproduzione
        if (currentPlayingAudioId && currentPlayingAudioId !== audioId) {
          // Metti in pausa l'audio attualmente in riproduzione
          const previousAudio = document.getElementById(currentPlayingAudioId);
          if (previousAudio) {
            previousAudio.pause();
            previousAudio.currentTime = 0; // Opzionale, resetta l'audio precedente all'inizio
          }
        }

        setLoading(true);
        try {
          // Resume AudioContext
          if (audioContextRef.current?.state === "suspended") {
            await audioContextRef.current.resume();
          }

          // Imposta un piccolo offset per saltare eventuali gap di inizializzazione
          audio.currentTime = 0.05;

          // Riproduci l'audio
          await audio.play();
          setCurrentPlayingAudioId(audioId);
          setIsPlaying(true);
        } catch (error) {
          showErrorToast(t("generic_errors:audio_playback_error"), {
            duration: 5000,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener("ended", () => {
        setIsPlaying(false);
        setCurrentPlayingAudioId(null);
      });
    }
  }, [setCurrentPlayingAudioId]);

  return (
    <div>
      {/* Hidden native audio element */}
      <audio ref={audioRef} src={audioSrc} preload="auto" />
      {/* Custom play/pause button */}
      <Button
        variant={{ mode: "ghost", size: "xs" }}
        onClick={togglePlayPause}
        aria-label={isPlaying ? "pause" : "play"}
        className={clsx("!px-0")}
      >
        {loading ? (
          <SpinnerIcon />
        ) : isPlaying ? (
          <div className={"flex items-center px-1"}>
            <PauseIcon className={clsx("w-4 h-4")} fill={"#8d8f91"} />
          </div>
        ) : (
          <VolumeMaxIcon />
        )}
      </Button>
    </div>
  );
};

export default CustomAudioPlayer;
