import { create } from "zustand";
import { persist } from "zustand/middleware";

type Data = {
  recording_error: boolean;
};

type DataStoreState = Data & {
  reset: () => void;
  setDataStore: (data: Data) => void;
};

const initialDataStore = {
  recording_error: false,
};

export const useDataStore = create<DataStoreState>()(
  persist(
    (set) => ({
      ...initialDataStore,
      reset: () => {
        set({
          ...initialDataStore,
        });
      },
      setDataStore: (data) => set({ recording_error: data.recording_error }),
    }),
    {
      name: "data-store",
    },
  ),
);
