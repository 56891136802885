import { Button, InfoCircleIcon, XCloseIcon } from "@translated/babele-react";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import toast, { Toast } from "react-hot-toast";

const CustomErrorToast = ({ t, message }: { t: Toast; message: string }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!t.visible) {
      setIsVisible(false);
    }
  }, [t.visible]);

  return (
    <div
      className={clsx(
        "bg-critical-100 border-1 border-critical-300",
        "grid grid-cols-[auto,1fr,auto] justify-start align-center",
        "rounded-sm p-4 gap-3",
        "shadow-md",
        "transition-all duration-300",
        isVisible ? "animate-fade-in-invert" : "animate-fade-out-invert",
      )}
    >
      <div
        className={clsx(
          "w-[24px] h-[24px]",
          "text-critical-800 bg-critical-300",
          "rounded-lg flex items-center justify-center",
        )}
      >
        <InfoCircleIcon />
      </div>
      <p className="text-neutral-1200 font-regular text-sm">{message}</p>
      <Button
        aria-label={"Close"}
        onClick={() => toast.remove(t.id)}
        variant={{
          tone: "neutral",
          mode: "ghost",
          size: "xs",
        }}
      >
        <XCloseIcon />
      </Button>
    </div>
  );
};

export default CustomErrorToast;
