import clsx from "clsx";
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  LongPressEventType,
  LongPressReactEvents,
  useLongPress,
} from "use-long-press";

import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { useDataStore } from "@/store/store.ts";
import { Tooltip } from "@/ui/Tooltip";

type PulseProps = {
  enabled: boolean;
  isLaraSpeaking: boolean;
  triggerAnimation: boolean;
  handleStopRecording: () => void;
  handleStartRecording: () => void;
  handleStopLara?: () => void;
  setTriggerAnimation: (value: boolean) => void;
  reachedLimit: boolean;
  setOpenDialogReachedLimit: (value: boolean) => void;
  disabled: boolean;
  showTooltipHoldToPress: boolean;
};

const Pulse = ({
  enabled = false,
  children,
  isLaraSpeaking,
  triggerAnimation,
  handleStopRecording,
  handleStartRecording,
  setTriggerAnimation,
  reachedLimit,
  setOpenDialogReachedLimit,
  disabled,
  showTooltipHoldToPress,
}: HTMLAttributes<HTMLElement> & PulseProps) => {
  const { t } = useTranslation();
  const [lastMove, setLastMove] = useState<{ x: number; y: number } | null>(
    null,
  );
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);
  const { recording_error } = useDataStore();

  const handlePressStart = useCallback(() => {
    if (reachedLimit) {
      setOpenDialogReachedLimit(true);
    } else {
      if (!enabled) {
        handleStartRecording();
      }
      if (isLaraSpeaking) {
        setTriggerAnimation(true);
      }
    }
    if ("vibrate" in navigator) {
      navigator.vibrate(200);
    } else {
      console.log("Vibration API not supported");
    }
  }, [
    reachedLimit,
    setOpenDialogReachedLimit,
    isLaraSpeaking,
    enabled,
    handleStartRecording,
    setTriggerAnimation,
  ]);

  const handlePressEnd = useCallback(() => {
    if (!reachedLimit && enabled) {
      handleStopRecording();
      if ("vibrate" in navigator) {
        navigator.vibrate(200);
      } else {
        console.log("Vibration API not supported");
      }
    }
  }, [reachedLimit, enabled, handleStopRecording]);

  const handleMove = useCallback(
    (e: LongPressReactEvents<HTMLElement>) => {
      const mouseEvent = e as React.MouseEvent<HTMLElement>;

      if (lastMove) {
        const moveThreshold = 50;
        const deltaX = Math.abs(mouseEvent.clientX - lastMove.x);
        const deltaY = Math.abs(mouseEvent.clientY - lastMove.y);

        if (deltaX > moveThreshold || deltaY > moveThreshold) {
          handlePressEnd();
        }
      }

      setLastMove({ x: mouseEvent.clientX, y: mouseEvent.clientY });
    },
    [lastMove, handlePressEnd],
  );

  const bind = useLongPress(() => {}, {
    onStart: handlePressStart,
    onFinish: handlePressEnd,
    onCancel: handlePressEnd,
    onMove: handleMove,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: 2000,
    cancelOutsideElement: true,
    detect: !isMobile ? LongPressEventType.Mouse : LongPressEventType.Touch,
  });

  useEffect(() => {
    if (triggerAnimation) {
      const timeout = setTimeout(() => {
        setTriggerAnimation(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [setTriggerAnimation, triggerAnimation]);

  return (
    <Tooltip
      open={showTooltipHoldToPress || recording_error}
      textColor={recording_error ? "text-critical-1000" : "text-primary-1000"}
      bgColor={recording_error ? "critical-100" : "primary-400"}
      title={t(
        recording_error
          ? "generic_errors:recording_error"
          : "generic_errors:min_rec_length",
      )}
      trigger={
        <div
          aria-pressed={enabled}
          tabIndex={0}
          className={clsx(
            "pulse-container",
            isLaraSpeaking && "lara-speaking",
            recording_error && "recording-error",
            enabled && "enabled",
            "transition-all duration-300",
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            !recording_error && disabled && " opacity-1",
          )}
          {...bind()}
        >
          {children}

          {triggerAnimation && (
            <>
              <span className="circle circle-1" />
              <span className="circle circle-2" />
            </>
          )}

          {(enabled || isLaraSpeaking) &&
            Array.from({ length: 4 }).map((_, index) => (
              <span
                key={index}
                className="wave"
                style={{ animationDelay: `${index * 0.3}s` }}
              />
            ))}
        </div>
      }
    />
  );
};

export default Pulse;
